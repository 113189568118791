import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';

const Streetscapes = () => (
  <Layout
    title="Streetscapes Calgary & Red Deer | Axiom Geomatics"
    description="Property development planning work often requires a Streetscape as part of the Development Permit application to the local municipality."
    keywords="streetscape, development permit, municipal survey, construction survey"
  >
    <main>
      <h1>Streetscapes</h1>
      <p>
        In survey parlance a Streetscape is the horizontal view, or street view, of a{' '}
        <a href="https://axiomgeomatics.ca/services/construction-services/development-permits">
          Development Permit Survey
        </a>
        . (A DP Survey is an aerial view.) A{' '}
        <strong>Streetscape is often a required part of a Development Permit application</strong> to
        the local municipality required for new construction or redevelopment on a property.
      </p>

      <p>
        Most municipalities that require a Streetscape do so to ensure architectural consistency of
        the area with respect to existing homes. In theory this ensures neighbourhood character is
        not disrupted by new developments.
      </p>

      <p>
        When creating a Streetscape, the surveyor takes 3-D measurements of your property and the
        adjacent properties, obtains high-resolution photos of the same. This data is imported into
        a special computer program that helps generate the finished product.
      </p>

      <h2>
        <abbr title="Frequently Asked Questions">FAQs</abbr>
      </h2>

      <ul>
        <li>
          <Link to="#chimneys">Why aren't chimneys shown on Streetscapes?</Link>
        </li>
        <li>
          <Link to="#partial-construction">
            Can I get a Streetscape for a partially-constructed house?
          </Link>
        </li>
        <li>
          <Link to="#corner-lot">What if I'm on a corner lot?</Link>
        </li>
        <li>
          <Link to="#beside-corner">What if I'm next to a corner lot?</Link>
        </li>
      </ul>

      <h3 id="chimneys">Why aren't chimneys shown on Streetscapes?</h3>
      <p>
        A <strong>Streetscape needs to show the highest peak of the house</strong>, which is the top
        of the roof. Chimneys should always exceed this height, but are not required nor part of a
        Streetscape document.
      </p>

      <h3 id="partial-construction">Can I get a Streetscape for a partially-constructed house?</h3>
      <p>
        Yes! The Streetscape will flag it as "under construction" and the finished document will
        show the property as-is at the time of the survey.
      </p>

      <h3 id="corner-lot">What if I'm on a corner lot?</h3>
      <p>
        A typical Streetscape shows the two houses to the left and right of your property, if they
        exist. On a corner lot the two to the side and one behind the subject lot are included, as
        per municipal requirements.
      </p>

      <h3 id="beside-corner">What if I'm next to a corner lot?</h3>
      <p>
        If you are next to a corner lot, we show only one house on the side with the closest corner.
      </p>
    </main>
  </Layout>
);

export default Streetscapes;
